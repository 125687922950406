import {
  Alert,
  AlertTitle,
  Button,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { FC, useCallback, useState } from 'react';

import { HTTPS_URL_REGEX } from '@variable';
import { updateApp } from '@action/app.action';
import App from '@interface/app.interface';
import { useAppDispatch } from '@hook/hooks.hook';

interface WebhookSectionProps {
  id: string;
  webhookUrl?: string;
}

const WebhookSection: FC<WebhookSectionProps> = ({
  id,
  webhookUrl: defaultWebhookUrl,
}) => {
  const dispatch = useAppDispatch();
  const [webhookUrl, setWebhookUrl] = useState(defaultWebhookUrl || '');

  const handleWebhookUrlChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newUrl = event.target.value;
    setWebhookUrl(newUrl);
  };

  const isUrlValid = useCallback(
    (url: string) => {
      return url !== defaultWebhookUrl && HTTPS_URL_REGEX.test(url);
    },
    [defaultWebhookUrl],
  );

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (isUrlValid(webhookUrl) && webhookUrl) {
        await dispatch(updateApp({ id, webhookUrl } as App));
      }
    },
    [dispatch, id, isUrlValid, webhookUrl],
  );

  return (
    <>
      <Stack>
        <Typography variant="h6" fontWeight="bold" color="primary">
          Configure Webhook URL
        </Typography>
        <Typography variant="subtitle1">
          We will call this POST endpoint with real-time notifications about
          your app
        </Typography>
      </Stack>

      {!defaultWebhookUrl && (
        <Alert severity="error">
          <AlertTitle>
            <Typography fontWeight="bold">
              Webhook Endpoint Not Set Up
            </Typography>
          </AlertTitle>
          <Typography>
            {`Set up a webhook URL to receive real-time notifications about your
            app's activity.`}
          </Typography>
        </Alert>
      )}
      <form onSubmit={handleSubmit}>
        <Stack
          mt={1}
          gap={2}
          direction="row"
          alignItems="baseline"
          flexDirection="column"
          justifyContent="flex-start"
        >
          <TextField
            required
            type="url"
            value={webhookUrl}
            label="Enter Webhook URL"
            onChange={handleWebhookUrlChange}
            sx={{ width: '100%', maxWidth: 400 }}
            placeholder='e.g., "https://example.com/webhook"'
            error={!isUrlValid}
            helperText={
              !isUrlValid
                ? 'Please enter a valid HTTPS URL'
                : 'This must be a POST endpoint'
            }
          />
          <Button
            type="submit"
            variant="outlined"
            disabled={!webhookUrl || !isUrlValid(webhookUrl)}
          >
            Save Webhook URL
          </Button>
        </Stack>
      </form>
    </>
  );
};

export default WebhookSection;
