import React from 'react';
import { Typography } from '@mui/material';

import { CompanyVerification } from '@interface/verificationRequest.interface';
import { COUNTRY_NAME } from '@variable';

interface CompanyVerificationProps {
  verification: CompanyVerification;
  renderDocumentThumbnails: () => JSX.Element[];
}

const CompanyVerificationComponent: React.FC<CompanyVerificationProps> = ({
  verification,
  renderDocumentThumbnails,
}) => {
  return (
    <div>
      <Typography variant="body1">
        <strong>Type:</strong> {verification.type}
      </Typography>
      <Typography variant="body1">
        <strong>Support Email:</strong> {verification.supportEmail}
      </Typography>
      <Typography variant="body1">
        <strong>Support Phone Number:</strong> +234{' '}
        {verification.supportPhoneNumber}
      </Typography>
      <Typography variant="body1">
        <strong>Country of Incorporation:</strong>{' '}
        {COUNTRY_NAME[verification.countryOfIncorporation as any]}
      </Typography>
      <Typography variant="body1">
        <strong>Registration Number:</strong> RC-
        {verification.registrationNumber}
      </Typography>
      {renderDocumentThumbnails()}
    </div>
  );
};

export default CompanyVerificationComponent;
