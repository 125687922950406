import {
  Box,
  Button,
  FormControlLabel,
  Stack,
  Switch,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import React, { FC, useState } from 'react';

import ConfirmationDialog from '@component/ConfirmationDialog';
import { activateCompany, suspendCompany } from '@action/company.action';
import { useAppDispatch } from '@hook/hooks.hook';
import { CompanyStatus } from '@enum/companyStatus.enum';

interface HeaderProps {
  id?: string;
  name?: string;
  createdAt?: string;
  status?: CompanyStatus;
}

const Header: FC<HeaderProps> = ({ createdAt, name, id, status }) => {
  const navigate = useNavigate();
  const theme: any = useTheme();
  const dispatch = useAppDispatch();
  const [openConfirmRequestVerification, setOpenConfirmRequestVerification] =
    useState(false);
  const isLive = status === CompanyStatus.ACTIVE;

  const confirmationDialogProps = {
    open: openConfirmRequestVerification,
    title: `Suspend ${name}!`,
    subtitle: `Are you sure you want to suspend ${name}?`,
    onClose: () => {
      setOpenConfirmRequestVerification(false);
    },
    onConfirm: () => {
      const action = isLive ? suspendCompany : activateCompany;
      dispatch(action(id as string));
    },
  };

  const handleTriggerSuspend = () => {
    setOpenConfirmRequestVerification(true);
  };

  const switchStyles = {
    '& .MuiSwitch-switchBase, & .MuiSwitch-switchBase.Mui-checked': {
      color: theme.palette[isLive ? 'success' : 'error'].main,
      '&:hover': {
        backgroundColor: isLive
          ? 'rgba(0, 255, 0, 0.08)'
          : 'rgba(255, 0, 0, 0.08)',
      },
    },
    '& .MuiSwitch-switchBase + .MuiSwitch-track, & .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track':
      {
        backgroundColor: theme.palette[isLive ? 'success' : 'error'].main,
      },
  };

  return (
    <>
      <Toolbar sx={{ justifyContent: 'space-between', mb: 1 }}>
        <Stack
          width="100%"
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Button
            variant="text"
            startIcon={<ArrowBack />}
            onClick={() => navigate(-1)}
          >
            Go Back
          </Button>

          <FormControlLabel
            control={
              <Switch
                checked={isLive}
                onChange={handleTriggerSuspend}
                sx={switchStyles}
              />
            }
            label={isLive ? 'Active' : 'Suspended'}
          />

          <Box>
            <Typography variant="h4" fontWeight="bold">
              {name}
            </Typography>
            <Typography variant="caption" fontWeight="bold">
              Joined: {dayjs(createdAt).format('d MMM YYYY')}
            </Typography>
          </Box>
        </Stack>
      </Toolbar>

      {openConfirmRequestVerification && (
        <ConfirmationDialog {...confirmationDialogProps} />
      )}
    </>
  );
};

export default Header;
