import React from 'react';
import { Typography } from '@mui/material';

import { CompanyDirectorVerification } from '@interface/verificationRequest.interface';
import { COUNTRY_NAME, getRegionName } from '@variable';

interface CompanyDirectorVerificationProps {
  verification: CompanyDirectorVerification;
  renderDocumentThumbnails: () => JSX.Element[];
}

const CompanyDirectorVerificationComponent: React.FC<
  CompanyDirectorVerificationProps
> = ({ verification, renderDocumentThumbnails }) => {
  return (
    <div>
      <Typography variant="body1">
        <strong>Full Name:</strong> {verification.fullName}
      </Typography>
      <Typography variant="body1">
        <strong>Date of Birth:</strong> {verification.dateOfBirth}
      </Typography>
      <Typography variant="body1">
        <strong>Nationality:</strong> {COUNTRY_NAME[verification.nationality]}
      </Typography>
      <Typography variant="body1">
        <strong>Phone Number:</strong> {verification.phoneNumber}
      </Typography>
      <Typography variant="body1">
        <strong>Email:</strong> {verification.email}
      </Typography>
      <Typography variant="body1">
        <strong>Address:</strong> {verification.street}, {verification.city},{' '}
        {getRegionName(verification.countryOfResidence, verification.state)},{' '}
        {COUNTRY_NAME[verification.countryOfResidence]}
      </Typography>
      {renderDocumentThumbnails()}
    </div>
  );
};

export default CompanyDirectorVerificationComponent;
