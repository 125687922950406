import React, { useEffect } from 'react';
import { Paper, Stack, Typography } from '@mui/material';

import { useAppDispatch, useAppSelector } from '@hook/hooks.hook';
import {
  selectIsFetchingVerificationRequests,
  selectVerificationRequests,
} from '@selector/requests.selector';
import AppLoader from '@component/AppLoader';
import { fetchRequests } from '@action/request.action';
import {
  BusinessOwnerKycVerification,
  CompanyDirectorVerification,
  CompanyVerification,
} from '@interface/verificationRequest.interface';

import VerificationList from './VerificationList';

const NoVerifications = () => (
  <Paper elevation={3}>
    <Stack spacing={2} p={4}>
      <Typography variant="h5" align="center">
        No verification requests found.
      </Typography>
    </Stack>
  </Paper>
);

const VerificationRequestsPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const verificationRequests = useAppSelector(selectVerificationRequests);
  const isFetchingVerificationRequests = useAppSelector(
    selectIsFetchingVerificationRequests,
  );

  useEffect(() => {
    dispatch(fetchRequests());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const noVerifications =
    !verificationRequests?.companyVerifications.length &&
    !verificationRequests?.companyDirectorVerifications.length &&
    !verificationRequests?.businessOwnerKycVerifications.length;

  const renderVerifications = ({
    companyVerifications,
    companyDirectorVerifications,
    businessOwnerKycVerifications,
  }: {
    companyVerifications: CompanyVerification[];
    companyDirectorVerifications: CompanyDirectorVerification[];
    businessOwnerKycVerifications: BusinessOwnerKycVerification[];
  }) => (
    <Stack spacing={2}>
      <VerificationList
        title="Business Owner KYC Verifications"
        subtitle="Pending KYC verification requests for business owners."
        verifications={businessOwnerKycVerifications}
      />
      <VerificationList
        title="Company Verifications"
        subtitle="Pending verification requests for registered companies."
        verifications={companyVerifications}
      />
      <VerificationList
        title="Company Director Verifications"
        subtitle="Pending verification requests for company directors."
        verifications={companyDirectorVerifications}
      />
    </Stack>
  );

  if (isFetchingVerificationRequests) return <AppLoader />;
  return noVerifications ? (
    <NoVerifications />
  ) : (
    renderVerifications({
      companyVerifications: verificationRequests?.companyVerifications || [],
      companyDirectorVerifications:
        verificationRequests?.companyDirectorVerifications || [],
      businessOwnerKycVerifications:
        verificationRequests?.businessOwnerKycVerifications || [],
    })
  );
};

export default VerificationRequestsPage;
