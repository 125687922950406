import React, { FC, useCallback, useState } from 'react';
import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  useTheme,
} from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import {
  MdAccountBalanceWallet,
  MdAssignment,
  MdAttachMoney,
  MdBusiness,
  MdDashboard,
  MdExitToApp,
  MdSettings,
} from 'react-icons/md';
import { FaNpm } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';
import debounce from 'lodash.debounce';

import { useAuth } from '@hook/useAuth.hook';

interface SidebarProps {
  open: boolean;
  toggleDrawer: (value?: any) => void;
}

const Sidebar: FC<SidebarProps> = ({ open, toggleDrawer }) => {
  const theme: any = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const { logout } = useAuth();
  const currentLocation =
    location.pathname.replace('/dashboard', '').replace('/', '') || 'overview';
  const [isHovering, setIsHovering] = useState(false);

  const onMouseEnterThrottle = useCallback(() => {
    setIsHovering(true);
    toggleDrawer(true);
  }, [toggleDrawer]);

  const onMouseLeaveThrottle = debounce(() => {
    setIsHovering(false);
    toggleDrawer(false);
  }, 200);

  const menuItems: any = (() => {
    return [
      { text: 'Overview', icon: <MdDashboard />, to: 'overview' },
      { text: 'Requests', icon: <MdAssignment />, to: 'requests' },
      { text: 'Companies', icon: <MdBusiness />, to: 'companies' },
      // { text: 'Apps', icon: <MdApps />, to: 'apps' },
      // { text: 'Users', icon: <MdPeople />, to: 'users' },
      { text: 'Wallets', icon: <MdAccountBalanceWallet />, to: 'wallets' },
      { text: 'Taxes & Fees', icon: <MdAttachMoney />, to: 'tax-fees' },
      { text: 'Settings', icon: <MdSettings />, to: 'settings' },
      {
        text: 'Documentation',
        icon: <FaNpm />,
        onClick: () => {
          window.open('https://www.npmjs.com/package/zinari-pay', '_blank');
        },
      },
      { text: 'Logout', icon: <MdExitToApp />, onClick: logout },
    ].filter(Boolean);
  })();

  const listButtonStyle = ({ index, to }: { index: number; to?: string }) => {
    const isCurrentLocation =
      to && new RegExp(to as string).test(currentLocation);

    const isLastItem = index === menuItems.length - 1;
    let bottom = isLastItem ? 0 : 80;
    const isSecondToLastItem = index === menuItems.length - 2;
    bottom = isSecondToLastItem ? 40 : bottom;

    return {
      width: '95%',
      position: index >= menuItems.length - 2 ? 'absolute' : 'initial',
      bottom,
      borderRadius: '0 30px 30px 0',
      backgroundColor: isCurrentLocation
        ? theme.palette.primary.main
        : 'initial',
      color: isCurrentLocation ? theme.palette.primary.contrastText : 'initial',
      '&:hover': {
        color: 'initial',
        backgroundColor: theme.palette.primary.light,
      },
    };
  };

  const isOpen = Boolean(open || isHovering);
  const drawerWidth = isOpen ? 180 : 64;

  return (
    <Drawer
      open={isOpen}
      variant="permanent"
      sx={{
        flexShrink: 0,
        width: drawerWidth,
        whiteSpace: 'nowrap',
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          transition: 'width 0.5s',
        },
      }}
    >
      <Box sx={{ pt: '26px', height: '-webkit-fill-available' }}>
        <IconButton onClick={toggleDrawer}>
          <MenuIcon />
        </IconButton>

        <List
          sx={{ height: `calc(100% - 40px)`, position: 'relative' }}
          onMouseLeave={onMouseLeaveThrottle}
          onMouseEnter={onMouseEnterThrottle}
        >
          {menuItems.map(({ text, icon, to, onClick }: any, index: number) => (
            <Tooltip arrow key={text} title={text} placement="right">
              <ListItemButton
                onClick={onClick ? onClick : () => navigate(to as string)}
                sx={listButtonStyle({ index, to })}
              >
                <ListItemIcon sx={{ color: 'inherit', minWidth: '38px' }}>
                  {icon}
                </ListItemIcon>
                {isOpen && <ListItemText primary={text} />}
              </ListItemButton>
            </Tooltip>
          ))}
        </List>
      </Box>
    </Drawer>
  );
};

export default Sidebar;
