import { Grid, Paper, Stack, Typography } from '@mui/material';

import Spinner from '@component/Spinner';
import { SpinnerVariant } from '@component/Spinner/Spinner';

const VerificationText = ({
  label,
  isLoading,
  isVerified,
  count,
}: {
  label: string;
  isLoading?: boolean;
  isVerified: boolean;
  count?: number;
}) => (
  <Typography variant="subtitle2">
    {label} {count ? `(${count} attempts)` : ''}:{' '}
    {isLoading ? (
      <Spinner variant={SpinnerVariant.ROTATE} size={12} />
    ) : isVerified ? (
      `✅`
    ) : (
      `❌`
    )}
  </Typography>
);

const Verifications = ({
  companyIsVerified,
  isFetchingCompanyById,
  businessOwnerIsVerified,
  companyVerificationAttempts,
  businessOwnerKycVerificationAttempts,
}: {
  companyIsVerified: boolean;
  isFetchingCompanyById: boolean;
  businessOwnerIsVerified: boolean;
  companyVerificationAttempts: number;
  businessOwnerKycVerificationAttempts: number;
}) => (
  <Grid item xs={12} sm={6} md={4}>
    <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
      Business Verifications
    </Typography>
    <Paper elevation={2} sx={{ p: 4 }}>
      <Stack gap={1}>
        <VerificationText
          isLoading={isFetchingCompanyById}
          label="Business Owner Verification"
          isVerified={businessOwnerIsVerified}
          count={businessOwnerKycVerificationAttempts}
        />

        <VerificationText
          label="Company Verification"
          isVerified={companyIsVerified}
          count={companyVerificationAttempts}
        />
      </Stack>
    </Paper>
  </Grid>
);

export default Verifications;
