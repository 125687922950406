import { Grid, Paper, Stack, Typography } from '@mui/material';

const BusinessOwnerDetails = ({
  firstName,
  lastName,
  phoneNumber,
  email,
}: {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
}) => {
  const onClick = () => {
    console.log('scroll to directors section');
  };

  return (
    <Grid item xs={12} sm={6} md={4} sx={{}}>
      <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
        Business Owner Details
      </Typography>

      <Paper
        elevation={2}
        sx={{
          p: 4,
          '&:hover': { cursor: 'pointer' },
        }}
        onClick={onClick}
      >
        <Stack gap={1}>
          <Typography variant="subtitle2">
            {firstName} {lastName}
          </Typography>
          <Typography variant="subtitle2">
            {email}, {phoneNumber}
          </Typography>
        </Stack>
      </Paper>
    </Grid>
  );
};

export default BusinessOwnerDetails;
