import React, { useCallback, useEffect, useState } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

import { useAppDispatch, useAppSelector } from '@hook/hooks.hook';
import { fetchCompanies } from '@action/company.action';
import { selectCompaniesData } from '@selector/companies.selector';
import PaginatedResult from '@interface/paginatedResult.interface';
import Company from '@interface/company.interface';

const ROWS = [
  'Name',
  'Type',
  'Country',
  'Email',
  'Phone Number',
  'Date Joined',
  'Verified',
];

const Companies: React.FC = () => {
  const [transactions, setTransactions] = useState<PaginatedResult<Company>>();
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(transactions?.currentPage || 0);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const dispatch = useAppDispatch();
  const [, setSearchPerformed] = useState(false);
  const navigate = useNavigate();
  const {
    data: companies,
    currentPage,
    totalRecords,
  } = useAppSelector(selectCompaniesData);

  useEffect(() => {
    dispatch(fetchCompanies({ page: 1 }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = useCallback(
    async (values?: any) => {
      const { newPage, newRowsPerPage } = values || {};
      const res = (await dispatch(
        fetchCompanies({
          page: (newPage || page || 0) + 1,
          limit: newRowsPerPage || rowsPerPage,
          searchQuery: searchTerm,
        }),
      )) as any;

      if (!res.error) {
        setTransactions(res.payload);
      }
      setSearchPerformed(true);
    },
    [dispatch, page, rowsPerPage, searchTerm],
  );

  const handlePageChange = async (_: unknown, newPage: number) => {
    setPage(newPage);
    await handleSearch({
      newPage,
    });
  };

  const handleRowsPerPageChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    await handleSearch({
      newRowsPerPage,
      newPage: 0,
    });
  };

  const goToCompanyPage = (id: string) => {
    navigate(id);
  };

  return (
    <>
      <Toolbar sx={{ justifyContent: 'space-between', mb: 1 }}>
        <Typography variant="h4">Manage Companies</Typography>

        <TextField
          variant="outlined"
          placeholder="Search Companies"
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
            setSearchPerformed(false);
          }}
          onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
          sx={{ width: '300px' }}
        />
      </Toolbar>

      <Paper sx={{ p: 4, mb: 4 }} elevation={3}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {ROWS.map((row) => (
                  <TableCell key={row}>{row}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {companies?.map(
                ({
                  id,
                  name,
                  type,
                  countryOfIncorporation,
                  supportEmail,
                  supportPhoneNumber,
                  isVerified,
                  createdAt,
                }) => (
                  <TableRow
                    key={id}
                    onClick={() => goToCompanyPage(id)}
                    sx={{
                      '&:hover': {
                        cursor: 'pointer',
                      },
                    }}
                  >
                    <TableCell>{name}</TableCell>
                    <TableCell>{type}</TableCell>
                    <TableCell>{countryOfIncorporation}</TableCell>
                    <TableCell>{supportEmail}</TableCell>
                    <TableCell>{supportPhoneNumber}</TableCell>
                    <TableCell>
                      {dayjs(createdAt).format('DD MMM YYYY hh:mm')}
                    </TableCell>
                    <TableCell>{isVerified ? 'Yes' : 'No'}</TableCell>
                  </TableRow>
                ),
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          component="div"
          count={totalRecords || 0}
          page={(currentPage || 1) - 1}
          onPageChange={handlePageChange}
          rowsPerPage={rowsPerPage || 0}
          rowsPerPageOptions={[30, 60, 100]}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </Paper>
    </>
  );
};

export default Companies;
