import {
  AppBar,
  IconButton,
  LinearProgress,
  Toolbar,
  Typography,
} from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import React, { FC } from 'react';

import { useAppSelector } from '@hook/hooks.hook';
import { selectGlobalLoaderIsLoading } from '@selector/auth.selector';

interface HeaderProps {
  toggleDrawer: () => void;
}

const Header: FC<HeaderProps> = ({ toggleDrawer }) => {
  const globalLoaderIsLoading = useAppSelector(selectGlobalLoaderIsLoading);

  return (
    <AppBar
      position="fixed"
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          onClick={toggleDrawer}
          aria-label="open drawer"
          sx={{ marginRight: '36px' }}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" noWrap component="div">
          ZinariPay Admin
        </Typography>
      </Toolbar>
      {globalLoaderIsLoading && <LinearProgress />}
    </AppBar>
  );
};

export default Header;
