import { Grid, Paper, Typography } from '@mui/material';

import { CompanyDirector } from '@interface/company.interface';
import DirectorCard from '@component/DirectorCard';

const CompanyDirectors = ({
  directors,
  onViewDirector,
}: {
  directors?: CompanyDirector[];
  onViewDirector: (director: CompanyDirector) => void;
}) => {
  return (
    <Grid item xs={12}>
      <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
        Company Directors
      </Typography>

      <Paper elevation={2} sx={{ p: 4 }}>
        <Grid container spacing={2}>
          {directors?.map((director) => (
            <Grid item key={director.id} xs={12} sm={6} md={4}>
              <DirectorCard
                director={director}
                onViewDirector={onViewDirector}
              />
            </Grid>
          ))}
        </Grid>
      </Paper>
    </Grid>
  );
};

export default CompanyDirectors;
