import { Grid, Paper, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { ROLE_MAP } from '@variable';
import User from '@interface/user.interface';

const EmployeeCard = ({
  id,
  role,
  email,
  lastName,
  firstName,
  phoneNumber,
}: User) => {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(`/dashboard/users/${id}`);
  };

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Paper
        elevation={2}
        key={id}
        onClick={onClick}
        sx={{
          p: 2,
          '&:hover': {
            cursor: 'pointer',
          },
        }}
      >
        <Typography variant="subtitle1" fontWeight="bold">
          {firstName} {lastName} ({ROLE_MAP[role]})
        </Typography>
        <Typography variant="subtitle2">{email}</Typography>
        <Typography variant="subtitle2">0{phoneNumber}</Typography>
      </Paper>
    </Grid>
  );
};

export default EmployeeCard;
