import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import BusinessIcon from '@mui/icons-material/Business';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import MoneyIcon from '@mui/icons-material/Money';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import GroupIcon from '@mui/icons-material/Group';
import CodeIcon from '@mui/icons-material/Code';
import PersonIcon from '@mui/icons-material/Person';
import { allCountries, CountryName, CountrySlug } from 'country-region-data';

import CompanyType from '@enum/companyType.enum';
import UserRole from '@enum/userRole.enum';
import { CryptoCurrency } from '@enum/cryptocurrency.enum';
import InitialState from '@interface/initialState.interface';
import ConfirmationDialogProps from '@interface/confirmationDialog.interface';
import PaginatedResult from '@interface/paginatedResult.interface';
import Company from '@interface/company.interface';
import TaxOrFeeAttributes from '@interface/taxOrFees.interface';

export const SLICE_NAME = 'zinari-pay-admin';
export const TOKEN_KEY = 'zinariPayAdminToken';
export const IS_PROD = process.env.NODE_ENV === 'production';
export const IS_DEV = !IS_PROD;
export const ORIGIN_URL = process.env.REACT_APP_ORIGIN_URL;
export const API_URL = process.env.REACT_APP_API_URL;
export const CLOUDINARY_CLOUD_NAME =
  process.env.REACT_APP_CLOUDINARY_CLOUD_NAME;
export const CLOUDINARY_API_KEY = process.env.REACT_APP_CLOUDINARY_API_KEY;
export const CLOUDINARY_API_SECRET =
  process.env.REACT_APP_CLOUDINARY_API_SECRET;
export const CLOUDINARY_UPLOAD_PRESET =
  process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET;
export const HTTPS_URL_REGEX =
  /^https:\/\/[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+(?:\/[^\s]*)?$/;
export const PHONE_NUMBER_REGEX = /^(?:\+234|0)?[789]\d{9}$/;
export const BVN_VALIDATION = /^\d{11}$/;
export const AUTH_0_AUDIENCE = process.env.REACT_APP_AUTH_0_AUDIENCE;
export const AUTH_0_DOMAIN = process.env.REACT_APP_AUTH_0_DOMAIN as string;
export const AUTH_0_CLIENT_ID = process.env
  .REACT_APP_AUTH_0_CLIENT_ID as string;
export const INITIAL_STATE: InitialState = {
  isAuthenticated: false,
  isAuthenticating: false,
  isChangingPassword: false,
  isFetchingWallets: false,
  isFetchingTaxesAndFees: false,
  wallets: [],
  apps: {},
  isFetchingCompanies: false,
  companies: {} as PaginatedResult<Company>,
  taxAndFees: {} as PaginatedResult<TaxOrFeeAttributes>,
  isFetchingVerificationRequests: false,
  confirmationDialogProps: {} as ConfirmationDialogProps,
  isFetchingCompany: {},
  isSuspendingCompany: false,
};

export const ALLOWED_CRYPTOCURRENCIES = [
  CryptoCurrency.USDT,
  CryptoCurrency.USDC,
];

export const COMPANY_TYPES = [
  { value: CompanyType.LLC, label: 'LLC' },
  { value: CompanyType.SOLE_PROPRIETOR, label: 'Sole Proprietor' },
  { value: CompanyType.PARTNERSHIP, label: 'Partnership' },
  { value: CompanyType.CORPORATION, label: 'Corporation' },
  { value: CompanyType.S_CORP, label: 'S-Corp' },
  { value: CompanyType.NONPROFIT, label: 'Nonprofit' },
  { value: CompanyType.COOPERATIVE, label: 'Cooperative' },
  { value: CompanyType.TRUST, label: 'Trust' },
];

export const UW_CONFIG = {
  cloudName: CLOUDINARY_CLOUD_NAME,
  uploadPreset: CLOUDINARY_UPLOAD_PRESET,
  cropping: true,
  croppingAspectRatio: 1,
  multiple: false,
  sources: ['local', 'url'],
  folder: 'app_logos',
  tags: ['apps', 'logos'],
  context: { alt: 'user_uploaded' },
  clientAllowedFormats: ['jpeg', 'png', 'webp', 'jpg'],
  maxImageFileSize: 1000000,
  maxImageWidth: 150,
  maxImageHeight: 150,
  signed: true,
};

export const CRYPTOCURRENCY_ICONS: {
  [key: string]: any;
} = Object.values(CryptoCurrency).reduce((memo: any, currency) => {
  memo[
    currency
  ] = require(`cryptocurrency-icons/svg/color/${currency.toLowerCase()}.svg`);
  return memo;
}, {});

export const ROLE_MAP: any = {
  [UserRole.SUPER_ADMIN]: 'Super Admin',
  [UserRole.ADMIN]: 'Admin',
  [UserRole.DEVELOPER]: 'Software Developer',
  [UserRole.BUSINESS_OWNER]: 'Business Owner',
  [UserRole.FINANCE]: 'Finance',
  [UserRole.CUSTOMER_SUPPORT]: 'Customer Support',
  [UserRole.BUSINESS_ACCOUNT_MANAGER]: 'Business Account Manager',
  [UserRole.BUSINESS_ADMIN]: 'Admin',
  [UserRole.USER]: 'User',
};

export const ALLOWED_NATIONALITIES = allCountries.reduce(
  (memo, [name, code, regions]) => {
    if (!code || !name || !regions) return memo;

    memo.push({
      code,
      name,
      regions: regions.reduce((regionMemo, [title, value]) => {
        regionMemo.push({ title, value });
        return regionMemo;
      }, [] as any),
    });
    return memo;
  },
  [] as {
    code: CountrySlug;
    name: CountryName;
    regions: {
      title: string;
      value: string;
    }[];
  }[],
);

export const COUNTRY_NAME = ALLOWED_NATIONALITIES.reduce(
  (memo, { code, name }) => {
    if (!code || !name) return memo;
    memo[code] = name;
    return memo;
  },
  {} as any,
);

export const ALLOWED_NATIONALITIES_MAP = ALLOWED_NATIONALITIES.map(
  ({ code: value, name: title }) => ({ value, title }),
);

export const STATES_MAP = ALLOWED_NATIONALITIES.reduce(
  (memo, { code, regions }) => {
    if (!code) return memo;
    memo[code] = regions;
    return memo;
  },
  {} as {
    [key: string]: {
      title: string;
      value: string;
    }[];
  },
);

export const getRegionName = (countrySlug: string, state: string) => {
  return STATES_MAP[countrySlug]?.find((curr) => curr.value === state)?.title;
};
export const USER_ROLES = [
  { title: ROLE_MAP[UserRole.BUSINESS_ADMIN], value: UserRole.BUSINESS_ADMIN },
  { title: ROLE_MAP[UserRole.DEVELOPER], value: UserRole.DEVELOPER },
  {
    title: ROLE_MAP[UserRole.CUSTOMER_SUPPORT],
    value: UserRole.CUSTOMER_SUPPORT,
  },
];

export const ROLE_ICON_MAP: Record<
  UserRole,
  OverridableComponent<SvgIconTypeMap>
> = {
  [UserRole.SUPER_ADMIN]: SupervisedUserCircleIcon,
  [UserRole.ADMIN]: AdminPanelSettingsIcon,
  [UserRole.BUSINESS_OWNER]: BusinessIcon,
  [UserRole.CUSTOMER_SUPPORT]: SupportAgentIcon,
  [UserRole.FINANCE]: MoneyIcon,
  [UserRole.BUSINESS_ACCOUNT_MANAGER]: AccountBoxIcon,
  [UserRole.BUSINESS_ADMIN]: GroupIcon,
  [UserRole.DEVELOPER]: CodeIcon,
  [UserRole.USER]: PersonIcon,
};
