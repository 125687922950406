import React from 'react';
import { Typography } from '@mui/material';

import { BusinessOwnerKycVerification } from '@interface/verificationRequest.interface';
import { COUNTRY_NAME, getRegionName } from '@variable';

interface BusinessOwnerKycVerificationProps {
  verification: BusinessOwnerKycVerification;
  renderDocumentThumbnails: () => JSX.Element[];
}

const BusinessOwnerKycVerificationComponent: React.FC<
  BusinessOwnerKycVerificationProps
> = ({ verification, renderDocumentThumbnails }) => {
  return (
    <div>
      <Typography variant="body1">
        <strong>Date of Birth:</strong> {verification.dateOfBirth}
      </Typography>
      <Typography variant="body1">
        <strong>Nationality:</strong> {COUNTRY_NAME[verification.nationality]}
      </Typography>
      <Typography variant="body1">
        <strong>Address:</strong> {verification.street}, {verification.city},{' '}
        {getRegionName(verification.countryOfResidence, verification.state)},{' '}
        {COUNTRY_NAME[verification.countryOfResidence]}
      </Typography>
      {renderDocumentThumbnails()}
    </div>
  );
};

export default BusinessOwnerKycVerificationComponent;
