import React from 'react';
import {
  Box,
  Card,
  CardContent,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { auto } from '@cloudinary/url-gen/actions/resize';
import { autoGravity } from '@cloudinary/url-gen/qualifiers/gravity';
import { AdvancedImage } from '@cloudinary/react';

import cld from '@localCloudinary';
import { AppMode } from '@enum/appMode.enum';
import App from '@interface/app.interface';

const AppCard: React.FC<App> = ({ id, name, logoUrl, mode, companyId }) => {
  const navigate = useNavigate();
  const theme: any = useTheme();
  const inProduction = mode === AppMode.PRODUCTION;

  const handleCardClick = () => {
    navigate(`/dashboard/companies/${companyId}/app/${id}`);
  };

  const img = cld
    .image(logoUrl)
    .format('auto')
    .quality('auto')
    .resize(auto().gravity(autoGravity()).width(100).height(100));

  return (
    <Card
      onClick={handleCardClick}
      sx={{ display: 'flex', cursor: 'pointer', mb: 2 }}
    >
      <AdvancedImage cldImg={img} />

      <Box
        sx={{ display: 'flex', flexDirection: 'column', position: 'relative' }}
      >
        <CardContent sx={{ flex: '1 0 auto' }}>
          <Typography>{name}</Typography>

          <Stack direction="row" gap={1} alignItems="center">
            <Box
              height={10}
              width={10}
              borderRadius={2}
              bgcolor={theme.palette[inProduction ? 'success' : 'error'].main}
            />
            <Typography variant="caption" color="text.secondary">
              {inProduction ? 'live' : 'development'}
            </Typography>
          </Stack>
        </CardContent>
      </Box>
    </Card>
  );
};

export default AppCard;
