import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import buildUrl from '@util/buildUrl.util';
import { API_URL, SLICE_NAME } from '@variable';
import Company from '@interface/company.interface';
import PaginatedResult from '@interface/paginatedResult.interface';

export const fetchCompanies = createAsyncThunk(
  `${SLICE_NAME}/fetchCompanies`,
  async ({
    page,
    limit,
    status,
    searchQuery,
  }: {
    page: number;
    limit?: number;
    status?: string;
    searchQuery?: string;
  }): Promise<PaginatedResult<Company>> => {
    const result = await axios(
      buildUrl(`${API_URL}/admin/companies`, {
        page,
        limit,
        status,
        searchQuery,
      }),
    );
    return result.data;
  },
);

export const fetchCompany = createAsyncThunk(
  `${SLICE_NAME}/fetchCompany`,
  async (id: string): Promise<Company> => {
    const result = await axios(`${API_URL}/admin/companies/${id}`);
    return result.data;
  },
);

export const suspendCompany = createAsyncThunk(
  `${SLICE_NAME}/suspendCompany`,
  async (id: string): Promise<Company> => {
    const result = await axios.put(`${API_URL}/admin/companies/${id}/suspend`);
    return result.data;
  },
);

export const activateCompany = createAsyncThunk(
  `${SLICE_NAME}/activateCompany`,
  async (id: string): Promise<Company> => {
    const result = await axios.put(`${API_URL}/admin/companies/${id}/activate`);
    return result.data;
  },
);
