import { Grid, Paper, Typography } from '@mui/material';

import AppLoader from '@component/AppLoader';
import User from '@interface/user.interface';
import EmployeeCard from '@page/Company/components/EmployeeCard';

const EmployeeDetails = ({
  isFetchingCompanyById,
  employees,
}: {
  isFetchingCompanyById: boolean;
  employees?: User[];
}) => {
  return (
    <Grid item xs={12}>
      <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
        Employees
      </Typography>

      <Paper elevation={2} sx={{ p: 4 }}>
        {isFetchingCompanyById ? (
          <AppLoader size={16} />
        ) : (
          <Grid container spacing={2}>
            {employees?.map((employee) => (
              <EmployeeCard {...employee} key={employee.id} />
            ))}
          </Grid>
        )}
      </Paper>

      {!employees?.length && !isFetchingCompanyById && (
        <Typography variant="subtitle1" gutterBottom>
          No employees added
        </Typography>
      )}
    </Grid>
  );
};

export default EmployeeDetails;
