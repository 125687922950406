import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@mui/material';
import { purple } from '@mui/material/colors';
import { AddCircle } from '@mui/icons-material';

import { useAppDispatch, useAppSelector } from '@hook/hooks.hook';
import { fetchWallets } from '@action/wallet.action';
import { selectWallets } from '@selector/wallet.selector';
import AddressDialog from '@page/Wallets/AddressDialog';

const Wallets: React.FC = () => {
  const dispatch = useAppDispatch();
  const data = useAppSelector(selectWallets);
  const [openAddWalletDialog, setOpenAddWalletDialog] = useState(false);
  useEffect(() => {
    dispatch(fetchWallets());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openRow = (address: string) => {
    window.open(`https://tronscan.org/#/address/${address}`, '_blank');
  };

  const onClick = () => {
    setOpenAddWalletDialog(true);
  };

  return (
    <>
      <Toolbar sx={{ justifyContent: 'space-between', mb: 1 }}>
        <Typography variant="h4">Wallets ({data?.length || 0})</Typography>

        <IconButton onClick={onClick} color={'primary'}>
          <AddCircle />
        </IconButton>
      </Toolbar>

      <Paper sx={{ p: 4, mb: 4 }} elevation={3}>
        {data?.length && (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Currency</TableCell>
                  <TableCell>Address</TableCell>
                  <TableCell>Balance</TableCell>
                  <TableCell>Assigned</TableCell>
                  <TableCell>Last Update</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map(
                  ({
                    id,
                    updatedAt,
                    address,
                    appTransactionId,
                    currency,
                    balance,
                  }) => (
                    <TableRow
                      key={id}
                      onClick={() => openRow(address)}
                      sx={{
                        cursor: 'pointer',
                        '&:hover': { bgcolor: purple[100] },
                      }}
                    >
                      <TableCell>{currency}</TableCell>
                      <TableCell>{address}</TableCell>
                      <TableCell>{balance}</TableCell>
                      <TableCell>{appTransactionId ? '✅' : ''}</TableCell>
                      <TableCell>{dayjs(updatedAt).fromNow()}</TableCell>
                    </TableRow>
                  ),
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Paper>
      <AddressDialog
        open={openAddWalletDialog}
        onClose={() => {
          setOpenAddWalletDialog(false);
        }}
      />
    </>
  );
};

export default Wallets;
