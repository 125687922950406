import { Field } from 'formik';
import { InputAdornment } from '@mui/material';
import React from 'react';

const PhoneNumberField = ({ ...props }) => {
  return (
    <Field
      {...props}
      InputProps={{
        startAdornment: <InputAdornment position="start">+234</InputAdornment>,
      }}
    />
  );
};

export default PhoneNumberField;
