import { Divider, Stack, Typography } from '@mui/material';
import React, { FC } from 'react';

import TabPanel from '@component/AppDetail/TabPanel';
import CopyToClipboard from '@component/CopyToClipboard';
import WebhookSection from '@component/AppDetail/WebhookSection';
import AppCurrencies from '@component/AppDetail/AppCurrencies';
import { CryptoCurrency } from '@enum/cryptocurrency.enum';

interface ApiInfoProps {
  id: string;
  value: number;
  webhookUrl?: string;
  publicKey: string;
  index: number;
  appCurrencies: string | CryptoCurrency[];
}

interface CopyableApiInfoProps {
  label: string;
  value: string;
}

const CopyableApiInfo: FC<CopyableApiInfoProps> = ({ label, value }) => {
  return (
    <Stack direction="row" gap={2} alignItems="center">
      <Typography>
        <b>{label}:</b> {`${value.substring(0, 8)}***`}
      </Typography>
      <CopyToClipboard value={value} />
    </Stack>
  );
};

const ApiInfo: FC<ApiInfoProps> = ({
  id,
  value,
  publicKey,
  webhookUrl: defaultWebhookUrl,
  index,
  appCurrencies,
}) => {
  const copyableApiInfo = [
    {
      label: 'App ID',
      value: id,
    },
    {
      label: 'Public Key',
      value: publicKey,
    },
  ];

  return (
    <TabPanel value={value} index={index}>
      <Stack spacing={3}>
        <Stack>
          <Typography variant="h6" fontWeight="bold" color="primary">
            Integration Information
          </Typography>
          <Typography variant="subtitle1">
            Use this information to integrate Zinari Pay into your application.
          </Typography>
        </Stack>

        {copyableApiInfo.map((apiInfo) => (
          <CopyableApiInfo {...apiInfo} key={apiInfo.label} />
        ))}
        <Divider />

        <AppCurrencies
          appCurrencies={JSON.parse(appCurrencies as string) || []}
          id={id}
        />

        <Divider />

        <WebhookSection id={id} webhookUrl={defaultWebhookUrl} />
      </Stack>
    </TabPanel>
  );
};

export default ApiInfo;
