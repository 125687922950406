enum VerificationDocType {
  COMPANY = 'company',
  COMPANY_DIRECTOR = 'companyDirector',
  BUSINESS_OWNER_KYC = 'businessOwnerKyc',
}

export enum VerificationType {
  APPROVE = 'approve',
  REJECT = 'reject',
}

export default VerificationDocType;
