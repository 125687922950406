import { useParams } from 'react-router-dom';
import { Grid, Paper } from '@mui/material';
import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '@hook/hooks.hook';
import {
  selectCompanyAppsByCompanyId,
  selectCompanyById,
  selectIsFetchingCompanyById,
} from '@selector/companies.selector';
import { fetchCompany } from '@action/company.action';
import AppLoader from '@component/AppLoader';
import Verifiers from '@page/Company/components/Verifiers';
import EmployeeDetails from '@page/Company/components/EmployeeDetails';
import Verifications from '@page/Company/components/Verifications';
import BusinessOwnerDetails from '@page/Company/components/BusinessOwnerDetails';
import CompanyApps from '@page/Company/components/CompanyApps';
import CompanyDirectors from '@page/Company/components/CompanyDirectors';
import DirectorDrawer from '@component/DirectorDrawer';
import { CompanyDirector } from '@interface/company.interface';
import Header from '@page/Company/components/Header';

const CompanyPage = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const company = useAppSelector((state) => selectCompanyById(state, { id }));
  const isFetchingCompanyById = useAppSelector((state) =>
    selectIsFetchingCompanyById(state, { id }),
  );
  const apps = useAppSelector((state) =>
    selectCompanyAppsByCompanyId(state, { id }),
  );
  const [currentDirector, setCurrentDirector] = useState<CompanyDirector>();

  const onCloseDrawer = () => {
    setCurrentDirector(undefined);
  };

  const {
    name,
    isVerified,
    businessOwner,
    businessOwnerKyc,
    directors,
    verifiedBy,
    createdAt,
    verificationAttempts,
    status,
    employees,
  } = company || {};

  useEffect(() => {
    if (id) dispatch(fetchCompany(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!name) return <AppLoader />;

  return (
    <>
      <Header createdAt={createdAt} name={name} id={id} status={status} />

      <Paper elevation={3} sx={{ p: 4 }}>
        <Grid container spacing={2}>
          <BusinessOwnerDetails
            firstName={businessOwner?.firstName as string}
            lastName={businessOwner?.lastName as string}
            phoneNumber={businessOwner?.phoneNumber as string}
            email={businessOwner?.email as string}
          />

          <Verifications
            companyVerificationAttempts={verificationAttempts as number}
            businessOwnerKycVerificationAttempts={
              businessOwnerKyc?.verificationAttempts as number
            }
            companyIsVerified={Boolean(isVerified)}
            isFetchingCompanyById={isFetchingCompanyById}
            businessOwnerIsVerified={Boolean(businessOwnerKyc?.isVerified)}
          />

          <Verifiers
            companyVerifiedBy={verifiedBy}
            isLoading={isFetchingCompanyById}
            businessOwnerKycVerifiedBy={businessOwnerKyc?.verifiedBy}
          />

          <CompanyDirectors
            directors={directors}
            onViewDirector={setCurrentDirector}
          />

          <EmployeeDetails
            isFetchingCompanyById={isFetchingCompanyById}
            employees={employees}
          />

          <CompanyApps
            isFetchingCompanyById={isFetchingCompanyById}
            apps={apps}
          />
        </Grid>
      </Paper>

      {currentDirector && (
        <DirectorDrawer
          open={Boolean(currentDirector)}
          director={currentDirector}
          onClose={onCloseDrawer}
        />
      )}
    </>
  );
};

export default CompanyPage;
