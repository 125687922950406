import { Navigate, useLocation } from 'react-router-dom';
import React, { FC, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { useAuth } from '@hook/useAuth.hook';
import { useAppSelector } from '@hook/hooks.hook';
import { selectUser } from '@selector/auth.selector';
import UserRole from '@enum/userRole.enum';

import AppLoader from '../AppLoader';

const NotVerified = () => <p>NotVerified</p>;

const ProtectedRoute: FC<{ children: any }> = ({ children }) => {
  const { isAuthenticated, isLoading } = useAuth0();
  const user = useAppSelector(selectUser);
  const { setRedirectPath } = useAuth();
  const { pathname, search } = useLocation();

  useEffect(() => {
    if (!isAuthenticated) setRedirectPath(pathname + search || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const showLoader =
    isLoading ||
    !user ||
    (user && ![UserRole.SUPER_ADMIN, UserRole.ADMIN].includes(user.role));
  if (!isAuthenticated) return <Navigate to="/login" />;
  if (showLoader) return <AppLoader />;
  return !user?.isVerified ? <NotVerified /> : children;
};

export default ProtectedRoute;
