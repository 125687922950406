import React, { useEffect, useState } from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Toolbar,
  Typography,
} from '@mui/material';

import { useAppDispatch, useAppSelector } from '@hook/hooks.hook';
import { fetchFeesAndTaxes } from '@action/taxFee.action';
import {
  selectIsFetchingTaxesAndFees,
  selectTaxesAndFees,
} from '@selector/taxFee.selector';
import TableComponent from '@page/TaxesFees/Table';
import AppLoader from '@component/AppLoader';

const TaxesFees: React.FC = () => {
  const dispatch = useAppDispatch();
  const { data, lastPage, currentPage, totalRecords, hasMorePages } =
    useAppSelector(selectTaxesAndFees);
  const isFetchingTaxesAndFees = useAppSelector(selectIsFetchingTaxesAndFees);
  const [type, setType] = useState(1);

  useEffect(() => {
    dispatch(fetchFeesAndTaxes({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeType = ({ target: { value } }: any) => {
    setType(value);
  };

  return (
    <>
      <Toolbar sx={{ justifyContent: 'space-between', mb: 1 }}>
        <Typography variant="h4">Taxes & Fees</Typography>

        <Box width={150}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Age</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={type}
              label="Type"
              onChange={handleChangeType}
            >
              <MenuItem value={1}>Taxes & Fees</MenuItem>
              <MenuItem value={2}>Fees</MenuItem>
              <MenuItem value={3}>Taxes</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Toolbar>

      {isFetchingTaxesAndFees && !data && (
        <Paper sx={{ p: 4, mb: 4 }} elevation={3}>
          <AppLoader size={10} />
        </Paper>
      )}

      {!isFetchingTaxesAndFees && !data?.length && (
        <Paper sx={{ p: 4, mb: 4 }} elevation={3}>
          <Typography>No taxes to display</Typography>
        </Paper>
      )}

      {data?.length && (
        <TableComponent
          data={data}
          lastPage={lastPage}
          currentPage={currentPage}
          totalRecords={totalRecords}
          hasMorePages={hasMorePages}
        />
      )}
    </>
  );
};

export default TaxesFees;
