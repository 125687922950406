import {
  ActionReducerMapBuilder,
  isAnyOf,
  isFulfilled,
  isPending,
  isRejected,
} from '@reduxjs/toolkit';

import InitialState from '@interface/initialState.interface';
import {
  authenticate,
  login,
  logout,
  refreshToken,
  updatePassword,
} from '@action/auth.action';
import { INITIAL_STATE, TOKEN_KEY } from '@variable';
import { fetchUserData, updateUser } from '@action/user.action';
import { approveOrRejectRequest, fetchRequests } from '@action/request.action';
import VerificationDocType from '@enum/verificationDocType.enum';
import {
  VerificationRequest,
  VerificationRequests,
} from '@interface/verificationRequest.interface';
import {
  activateCompany,
  fetchCompanies,
  fetchCompany,
  suspendCompany,
} from '@action/company.action';
import { activateApp, suspendApp } from '@action/app.action';
import { addAddress, fetchWallets } from '@action/wallet.action';
import { fetchFeesAndTaxes } from '@action/taxFee.action';

export const extraReducers = (
  builder: ActionReducerMapBuilder<InitialState>,
) => {
  builder.addMatcher(
    isFulfilled(login, authenticate, refreshToken, fetchUserData, updateUser),
    (state: InitialState, { payload }) => {
      const { token, ...user } = payload || ({} as any);

      const newState = {
        ...state,
        isAuthenticating: false,
        isUpdatingUser: false,
        isAuthenticated: true,
      };

      if (token) window.sessionStorage.setItem(TOKEN_KEY, token);
      if (Object.values(user || {}).length)
        newState.user = { ...newState.user, ...user };

      return newState;
    },
  );

  builder.addMatcher(updatePassword.pending.match, (state: InitialState) => {
    return { ...state, isChangingPassword: true };
  });

  builder.addMatcher(
    isAnyOf(updatePassword.rejected.match, updatePassword.fulfilled.match),
    (state: InitialState) => {
      return { ...state, isChangingPassword: false };
    },
  );

  builder.addMatcher(
    isPending(login, authenticate, refreshToken, fetchUserData),
    (state: InitialState) => {
      return { ...state, isAuthenticating: true };
    },
  );

  builder.addMatcher(
    isPending(suspendApp, activateApp),
    (state: InitialState) => {
      return { ...state, isSuspendingApp: true };
    },
  );

  builder.addMatcher(
    isRejected(suspendApp, activateApp),
    (state: InitialState) => {
      return { ...state, isSuspendingApp: false };
    },
  );

  builder.addMatcher(
    isFulfilled(suspendApp, activateApp),
    (state: InitialState, { payload: updatedApp }) => {
      const { companyId } = updatedApp;

      return {
        ...state,
        isSuspendingApp: false,
        apps: {
          ...state.apps,
          [companyId]: state.apps?.[companyId]?.map((app) => {
            if (updatedApp.id === app.id) return { ...app, ...updatedApp };
            return app;
          }),
        },
      };
    },
  );

  builder.addMatcher(
    isPending(suspendCompany, activateCompany),
    (state: InitialState) => {
      return { ...state, isSuspendingCompany: true };
    },
  );

  builder.addMatcher(
    isRejected(suspendCompany, activateCompany),
    (state: InitialState) => {
      return { ...state, isSuspendingCompany: false };
    },
  );

  builder.addMatcher(
    isFulfilled(suspendCompany, activateCompany),
    (state: InitialState, { payload: updatedCompany }) => {
      return {
        ...state,
        isSuspendingCompany: false,
        companies: {
          ...state.companies,
          data: state.companies?.data.map((company) => {
            if (company.id === updatedCompany.id)
              return { ...company, ...updatedCompany };
            return company;
          }),
        },
      };
    },
  );

  builder.addMatcher(addAddress.pending.match, (state: InitialState) => {
    return { ...state, isFetchingWallets: true };
  });

  builder.addMatcher(addAddress.rejected.match, (state: InitialState) => {
    return { ...state, isFetchingWallets: false };
  });

  builder.addMatcher(
    addAddress.fulfilled.match,
    (state: InitialState, { payload: wallet }) => {
      return {
        ...state,
        isFetchingWallets: false,
        wallets: [...state.wallets].concat(wallet),
      };
    },
  );

  builder.addMatcher(fetchWallets.pending.match, (state: InitialState) => {
    return { ...state, isFetchingWallets: true };
  });

  builder.addMatcher(fetchWallets.rejected.match, (state: InitialState) => {
    return { ...state, isFetchingWallets: false };
  });

  builder.addMatcher(
    fetchWallets.fulfilled.match,
    (state: InitialState, { payload: wallets }) => {
      return { ...state, isFetchingWallets: false, wallets };
    },
  );

  builder.addMatcher(
    approveOrRejectRequest.pending.match,
    (state: InitialState) => {
      return { ...state, isVerifyingRequest: true };
    },
  );

  builder.addMatcher(
    approveOrRejectRequest.rejected.match,
    (state: InitialState) => {
      return { ...state, isVerifyingRequest: false };
    },
  );

  builder.addMatcher(
    approveOrRejectRequest.fulfilled.match,
    (
      state: InitialState,
      {
        meta: {
          arg: { id, docType },
        },
      },
    ) => {
      const isCompany = docType === VerificationDocType.COMPANY;
      const isCompanyDirector =
        docType === VerificationDocType.COMPANY_DIRECTOR;
      let key = 'businessOwnerKycVerifications';

      if (isCompany) key = 'companyVerifications';
      if (isCompanyDirector) key = 'companyDirectorVerifications';

      return {
        ...state,
        isVerifyingRequest: false,
        verificationRequests: {
          ...((state.verificationRequests as VerificationRequests) || {}),
          [key]: (state.verificationRequests as any)?.[key as any].filter(
            (verificationRequest: VerificationRequest) =>
              verificationRequest.id !== id,
          ),
        },
      };
    },
  );

  builder.addMatcher(
    fetchCompany.pending.match,
    (state: InitialState, { meta: { arg: id } }) => {
      return {
        ...state,
        isFetchingCompany: {
          ...state.isFetchingCompany,
          [id]: true,
        },
      };
    },
  );

  builder.addMatcher(
    fetchCompany.rejected.match,
    (state: InitialState, { meta: { arg: id } }) => {
      return {
        ...state,
        isFetchingCompany: {
          ...state.isFetchingCompany,
          [id]: false,
        },
      };
    },
  );

  builder.addMatcher(
    fetchCompany.fulfilled.match,
    (state: InitialState, { payload: { apps, ...updatedCompany } }) => {
      const { data } = state.companies;
      const companies = { ...state.companies };

      if (!data?.length) {
        companies.data = [updatedCompany];
      } else {
        let hasBeenFound = false;
        companies.data = companies.data.map((company) => {
          if (company.id === updatedCompany.id) {
            hasBeenFound = true;
            return updatedCompany;
          }
          return company;
        });
        if (!hasBeenFound) companies.data.push(updatedCompany);
      }

      return {
        ...state,
        companies,
        apps: { ...state.apps, [updatedCompany.id]: apps },
        isFetchingCompany: {
          ...state.isFetchingCompany,
          [updatedCompany.id]: false,
        },
      };
    },
  );

  builder.addMatcher(fetchCompanies.pending.match, (state: InitialState) => {
    return { ...state, isFetchingCompanies: true };
  });

  builder.addMatcher(fetchCompanies.rejected.match, (state: InitialState) => {
    return { ...state, isFetchingCompanies: false };
  });

  builder.addMatcher(
    fetchCompanies.fulfilled.match,
    (state: InitialState, { payload: companies }) => {
      return {
        ...state,
        isFetchingCompanies: false,
        companies:
          companies.currentPage === 1
            ? companies
            : {
                ...companies,
                data: (state.companies?.data || []).concat(companies?.data),
              },
      };
    },
  );

  builder.addMatcher(fetchFeesAndTaxes.pending.match, (state: InitialState) => {
    return { ...state, isFetchingTaxesAndFees: true };
  });

  builder.addMatcher(
    fetchFeesAndTaxes.rejected.match,
    (state: InitialState) => {
      return { ...state, isFetchingTaxesAndFees: false };
    },
  );

  builder.addMatcher(
    fetchFeesAndTaxes.fulfilled.match,
    (state: InitialState, { payload: taxAndFees }) => {
      return {
        ...state,
        isFetchingTaxesAndFees: false,
        taxAndFees:
          taxAndFees.currentPage === 1
            ? taxAndFees
            : {
                ...taxAndFees,
                data: (state.taxAndFees?.data || []).concat(taxAndFees?.data),
              },
      };
    },
  );

  builder.addMatcher(fetchRequests.pending.match, (state: InitialState) => {
    return { ...state, isFetchingVerificationRequests: true };
  });

  builder.addMatcher(fetchRequests.rejected.match, (state: InitialState) => {
    return { ...state, isFetchingVerificationRequests: false };
  });

  builder.addMatcher(
    fetchRequests.fulfilled.match,
    (state: InitialState, { payload: verificationRequests }) => {
      return {
        ...state,
        verificationRequests,
        isFetchingVerificationRequests: false,
      };
    },
  );

  builder.addMatcher(
    isAnyOf(
      logout.match,
      login.rejected.match,
      authenticate.rejected.match,
      refreshToken.rejected.match,
      fetchUserData.rejected.match,
    ),
    () => {
      window.sessionStorage.removeItem(TOKEN_KEY);
      return INITIAL_STATE;
    },
  );
};

export const reducers = {};
