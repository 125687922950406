import React, { useState } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import dayjs from 'dayjs';

import TaxOrFee from '@enum/taxOrFee.enum';

const getType = (type: TaxOrFee) => {
  switch (type) {
    case TaxOrFee.TRANSACTION_TAX:
      return 'Tx Tax';
    case TaxOrFee.TRANSACTION_FEE:
      return 'Tx Fee';
    case TaxOrFee.WITHDRAWAL_FEE:
      return 'Withdrawal Fee';
    default:
      throw new Error('Invalid type');
  }
};

const TableComponent = ({ data, currentPage, totalRecords }: any) => {
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const handleChangePage = (event: unknown, newPage: number) => {
    // Handle page change
    console.log('Page changed to:', newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    // Reset to the first page when rows per page change
    handleChangePage(null, 0);
  };

  return (
    <Paper sx={{ p: 4, mb: 4 }} elevation={3}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Company Name</TableCell>
              <TableCell>Country</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map(
              ({
                id,
                createdAt,
                companyName,
                country,
                type,
                cryptocurrencyAmount,
                cryptocurrency,
              }: any) => (
                <TableRow key={id}>
                  <TableCell>
                    {dayjs(createdAt).format('DD MMM YYYY')}
                  </TableCell>
                  <TableCell>{getType(type)}</TableCell>
                  <TableCell>
                    {cryptocurrencyAmount} {cryptocurrency}
                  </TableCell>
                  <TableCell>{companyName}</TableCell>
                  <TableCell>{country}</TableCell>
                </TableRow>
              ),
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={totalRecords || 0}
        page={(currentPage ?? 1) - 1}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[50, 100, 200]}
        labelRowsPerPage="Items per page"
      />
    </Paper>
  );
};
export default TableComponent;
