import React from 'react';
import { Chip, ListItemButton, ListItemText } from '@mui/material';
import dayjs, { extend } from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

extend(relativeTime);

interface VerificationItemProps {
  companyName: string;
  firstName: string;
  lastName: string;
  fullName: string;
  updatedAt: string;
  onClick: () => void;
}

const determineSeverity = (updatedAt: string) => {
  const diff = dayjs().diff(dayjs(updatedAt), 'hour');
  if (diff <= 24) return 'good';
  if (diff <= 36) return 'notGood';
  return 'bad';
};

const VerificationItem: React.FC<VerificationItemProps> = ({
  firstName,
  lastName,
  fullName,
  companyName,
  updatedAt,
  onClick,
}) => {
  let name = fullName;
  if (firstName) name = `${firstName} ${lastName}`;

  const severity = determineSeverity(updatedAt);

  const severityColor = {
    good: 'green',
    notGood: 'orange',
    bad: 'red',
  }[severity];

  return (
    <ListItemButton onClick={onClick} divider dense>
      <ListItemText
        primary={companyName || name}
        secondary={
          <>
            {name ? `${name} •` : ''} {dayjs(updatedAt).fromNow()}
          </>
        }
      />
      <Chip
        label={severity}
        size="small"
        style={{ backgroundColor: severityColor, color: 'white' }}
      />
    </ListItemButton>
  );
};

export default VerificationItem;
