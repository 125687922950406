import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Typography } from '@mui/material';

import { useAppDispatch, useAppSelector } from '@hook/hooks.hook';
import AppDetail from '@component/AppDetail';
import AppLoader from '@component/AppLoader';
import {
  selectCompanyAppsByCompanyIdAndAppId,
  selectIsFetchingCompanyById,
} from '@selector/companies.selector';
import { fetchCompany } from '@action/company.action';

const ViewCreatedApp = () => {
  const { id, appId } = useParams();
  const dispatch = useAppDispatch();

  const isFetchingCompanyById = useAppSelector((state) =>
    selectIsFetchingCompanyById(state, { id }),
  );

  const app = useAppSelector((state) =>
    selectCompanyAppsByCompanyIdAndAppId(state, { id, appId }),
  );

  useEffect(() => {
    if (id) dispatch(fetchCompany(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isFetchingCompanyById) return <AppLoader />;

  if (!app) return <Typography>App Not Found</Typography>;

  return <AppDetail {...app} />;
};

export default ViewCreatedApp;
