import { Grid, Paper, Typography } from '@mui/material';

import AppLoader from '@component/AppLoader';
import App from '@interface/app.interface';
import AppCard from '@component/AppCard';

const CompanyApps = ({
  isFetchingCompanyById,
  apps,
}: {
  isFetchingCompanyById: boolean;
  apps?: App[];
}) => (
  <Grid item xs={12}>
    <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
      Apps
    </Typography>

    <Paper elevation={2} sx={{ p: 4 }}>
      {isFetchingCompanyById ? (
        <AppLoader size={16} />
      ) : (
        <Grid container spacing={2}>
          {apps?.map((app) => (
            <Grid item key={app.id} xs={12} sm={6} md={4}>
              <AppCard {...app} />
            </Grid>
          ))}
        </Grid>
      )}

      {!apps?.length && !isFetchingCompanyById && (
        <Typography variant="subtitle2" gutterBottom>
          No Apps added
        </Typography>
      )}
    </Paper>
  </Grid>
);

export default CompanyApps;
