import React, { useCallback } from 'react';
import { Divider, Paper, Typography } from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { updatePassword } from '@action/auth.action';
import { useAppDispatch } from '@hook/hooks.hook';
import ChangePasswordForm from '@page/Settings/ChangePasswordForm';

const INITIAL_VALUES = {
  oldPassword: '',
  newPassword: '',
  repeatPassword: '',
};

const PASSWORD_SCHEMA = Yup.object({
  oldPassword: Yup.string().required('Required'),
  newPassword: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
    .matches(/[0-9]/, 'Password must contain at least one number')
    .matches(
      /[!@#$%^&*(),.?":{}|<>]/,
      'Password must contain at one special character',
    )
    .required('Required'),
  repeatPassword: Yup.string()
    .oneOf([Yup.ref('newPassword')], 'Passwords must match')
    .required('Required'),
});

const ChangePassword = () => {
  const dispatch = useAppDispatch();

  const handleSubmit = useCallback(
    async (values: any, { resetForm }: any) => {
      const res = (await dispatch(
        updatePassword({
          oldPassword: values.oldPassword,
          password: values.newPassword,
        }),
      )) as any;

      if (!res.error) {
        resetForm({
          values: INITIAL_VALUES,
        });
      }
    },
    [dispatch],
  );

  return (
    <Paper elevation={3} sx={{ p: 4 }}>
      <Typography variant="h6" fontWeight="bold">
        Manage Password & 2FA
      </Typography>

      <Divider variant="fullWidth" sx={{ mt: 2, mb: 4 }} />

      <Formik
        enableReinitialize
        onSubmit={handleSubmit}
        initialValues={INITIAL_VALUES}
        validationSchema={PASSWORD_SCHEMA}
      >
        {() => <ChangePasswordForm />}
      </Formik>
    </Paper>
  );
};

export default ChangePassword;
