const buildUrl = (
  baseUrl: string,
  params: Record<string, string | number | undefined>,
): string => {
  const queryParams = Object.entries(params)
    .filter(([, value]) => value !== undefined && value !== null)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value as string)}`,
    )
    .join('&');

  return `${baseUrl}${queryParams ? `?${queryParams}` : ''}`;
};

export default buildUrl;
