import React, { FC, useState } from 'react';
import { Button, List, Paper } from '@mui/material';

import {
  BusinessOwnerKycVerification,
  CompanyDirectorVerification,
  CompanyVerification,
  VerificationRequest,
} from '@interface/verificationRequest.interface';
import SectionHeader from '@page/Requests/SectionHeader';
import VerificationItem from '@page/Requests/VerificationItem';
import VerificationDialog from '@page/Requests/VerificationDialog';

interface VerificationListProps {
  title: string;
  subtitle: string;
  verifications:
    | CompanyVerification[]
    | CompanyDirectorVerification[]
    | BusinessOwnerKycVerification[];
}

const VerificationList: FC<VerificationListProps> = ({
  title,
  subtitle,
  verifications,
}) => {
  const [visibleCount, setVisibleCount] = useState(10);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedVerification, setSelectedVerification] =
    useState<VerificationRequest | null>(null);

  const loadMore = () => {
    setVisibleCount(visibleCount + 10);
  };

  const handleOpenDialog = (verification: VerificationRequest) => {
    setSelectedVerification(verification);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  return verifications.length ? (
    <Paper elevation={2} sx={{ p: 4 }}>
      <SectionHeader title={title} subtitle={subtitle} />
      <List>
        {verifications.slice(0, visibleCount).map((verification, index) => (
          <VerificationItem
            key={index}
            {...(verification as any)}
            onClick={() => handleOpenDialog(verification)}
          />
        ))}
      </List>
      {verifications.length > visibleCount && (
        <Button onClick={loadMore} variant="contained" color="primary">
          Load More
        </Button>
      )}
      {selectedVerification && (
        <VerificationDialog
          open={dialogOpen}
          onClose={handleCloseDialog}
          verification={selectedVerification}
        />
      )}
    </Paper>
  ) : null;
};

export default VerificationList;
