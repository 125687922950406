import React, { useCallback, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';

import { CryptoCurrency } from '@enum/cryptocurrency.enum';
import { useAppDispatch } from '@hook/hooks.hook';
import { addAddress } from '@action/wallet.action';
import LoadingButton from '@component/LoadingButton';

const AddressDialog = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const [address, setAddress] = useState('');
  const [privateKey, setPrivateKey] = useState('');
  const [crypto, setCrypto] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleAddressChange = (event: any) => setAddress(event.target.value);
  const handlePrivateKeyChange = (event: any) =>
    setPrivateKey(event.target.value);
  const handleCryptoChange = (event: any) => setCrypto(event.target.value);
  const dispatch = useAppDispatch();

  const onSubmit = useCallback(async () => {
    setIsLoading(true);
    const res: any = await dispatch(
      addAddress({
        address,
        privateKey,
        currency: crypto,
      }),
    );

    if (!res.error) onClose();

    setIsLoading(false);
  }, [address, crypto, dispatch, privateKey, onClose]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Enter Wallet Details</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          margin="dense"
          label="Address"
          value={address}
          onChange={handleAddressChange}
        />
        <TextField
          fullWidth
          margin="dense"
          label="Private Key"
          value={privateKey}
          onChange={handlePrivateKeyChange}
          type="password"
        />
        <FormControl fullWidth margin="dense">
          <InputLabel>Cryptocurrency</InputLabel>
          <Select value={crypto} onChange={handleCryptoChange}>
            <MenuItem value={CryptoCurrency.USDT}>Tether (USDT)</MenuItem>
            <MenuItem value={CryptoCurrency.USDC}>USD Coin (USDC)</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <LoadingButton isLoading={isLoading} onClick={onSubmit}>
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddressDialog;
