import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { API_URL, SLICE_NAME } from '@variable';
import PaginatedResult from '@interface/paginatedResult.interface';
import TaxOrFeeAttributes from '@interface/taxOrFees.interface';
import buildUrl from '@util/buildUrl.util';

export const fetchFeesAndTaxes = createAsyncThunk(
  `${SLICE_NAME}/fetchFeesAndTaxes`,
  async ({
    page = 1,
    limit = 50,
    type,
    country,
  }: {
    page?: number;
    limit?: number;
    type?: string;
    country?: string;
  }): Promise<PaginatedResult<TaxOrFeeAttributes>> => {
    const result = await axios(
      buildUrl(`${API_URL}/admin/fees-taxes`, {
        page,
        limit,
        type,
        country,
      }),
    );
    return result.data;
  },
);
