import React, { FC, useCallback, useState } from 'react';
import {
  Box,
  Button,
  FormControlLabel,
  Stack,
  Switch,
  Tab,
  Tabs,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { auto } from '@cloudinary/url-gen/actions/resize';
import { autoGravity } from '@cloudinary/url-gen/qualifiers/gravity';
import { AdvancedImage } from '@cloudinary/react';

import cld from '@localCloudinary';
import TaxAndSecurity from '@component/AppDetail/TaxAndSecurity';
import App from '@interface/app.interface';
import { activateApp, suspendApp } from '@action/app.action';
import { useAppDispatch } from '@hook/hooks.hook';
import { AppStatus } from '@enum/appStatus.enum';
import { AppMode } from '@enum/appMode.enum';

import BasicInfo from './BasicInfo';
import AppWallet from './AppWallet';
import ApiInfo from './ApiInfo';

const TABS = ['Basic Info', 'API', 'App Wallet', 'Tax & Security'];

const AppDetail: FC<App> = ({
  id,
  status,
  name,
  logoUrl,
  appWallets,
  mode,
  appCurrencies,
  appKey,
  webhookUrl,
  customerPaysTax,
  whitelistedUrls,
  customerPaysFees,
}) => {
  const navigate = useNavigate();
  const [value, setValue] = useState<number>(0);
  const theme: any = useTheme();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const isUpdatingApp = false;

  const dispatch = useAppDispatch();

  const isLive = status === AppStatus.ACTIVE;
  const inProduction = mode === AppMode.PRODUCTION;

  const handleModeChange = useCallback(() => {
    const action = isLive ? suspendApp : activateApp;
    if (!isUpdatingApp) dispatch(action(id));
  }, [dispatch, id, isLive, isUpdatingApp]);

  const img = cld
    .image(logoUrl)
    .format('auto')
    .quality('auto')
    .resize(auto().gravity(autoGravity()).width(20).height(20));

  const switchStyles = (isTrue: boolean) => ({
    '& .MuiSwitch-switchBase, & .MuiSwitch-switchBase.Mui-checked': {
      color: theme.palette[isTrue ? 'success' : 'error'].main,
      '&:hover': {
        backgroundColor: isTrue
          ? 'rgba(0, 255, 0, 0.08)'
          : 'rgba(255, 0, 0, 0.08)',
      },
    },
    '& .MuiSwitch-switchBase + .MuiSwitch-track, & .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track':
      {
        backgroundColor: theme.palette[isTrue ? 'success' : 'error'].main,
      },
  });

  const statusStyles = switchStyles(isLive);

  const modeStyles = switchStyles(inProduction);

  return (
    <>
      <Toolbar>
        <Stack
          direction="row"
          marginBottom={1}
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <Button
            variant="text"
            startIcon={<ArrowBack />}
            onClick={() => navigate(-1)}
          >
            Go Back
          </Button>

          <FormControlLabel
            control={
              <Switch
                checked={isLive}
                onChange={handleModeChange}
                sx={statusStyles}
              />
            }
            label={isLive ? 'Active' : 'Suspended'}
          />

          <FormControlLabel
            disabled={isUpdatingApp}
            control={
              <Switch
                checked={inProduction}
                onChange={console.log}
                sx={modeStyles}
                disabled={isUpdatingApp}
              />
            }
            label={inProduction ? 'In Production' : 'In Development'}
          />

          <Stack
            gap={1}
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <AdvancedImage cldImg={img} />
            <Typography variant="h6" fontWeight="bold">
              {name}
            </Typography>
          </Stack>
        </Stack>
      </Toolbar>

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="app detail tabs"
          sx={{ background: 'white' }}
          scrollButtons="auto"
          visibleScrollbar
        >
          {TABS.map((tab) => (
            <Tab label={tab} key={tab} />
          ))}
        </Tabs>
      </Box>

      <BasicInfo
        id={id}
        name={name}
        value={value}
        logoUrl={logoUrl}
        index={0}
      />

      <ApiInfo
        id={id}
        value={value}
        publicKey={appKey.decryptedPublicKey}
        webhookUrl={webhookUrl}
        appCurrencies={appCurrencies}
        index={1}
      />

      <AppWallet value={value} wallets={appWallets} index={2} />

      <TaxAndSecurity
        id={id}
        index={3}
        value={value}
        customerPaysTax={customerPaysTax}
        whitelistedUrls={whitelistedUrls}
        customerPaysFees={customerPaysFees}
      />
    </>
  );
};

export default AppDetail;
