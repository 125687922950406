import { Grid, Paper, Stack, Typography } from '@mui/material';

import Spinner from '@component/Spinner';
import { SpinnerVariant } from '@component/Spinner/Spinner';
import User from '@interface/user.interface';

const VerifierText = ({
  label,
  isLoading,
  firstName,
  lastName,
}: {
  label: string;
  isLoading?: boolean;
  firstName?: string;
  lastName?: string;
}) => (
  <Typography variant="subtitle2">
    {label}:{' '}
    {isLoading ? (
      <Spinner variant={SpinnerVariant.ROTATE} size={12} />
    ) : firstName ? (
      `${firstName} ${lastName}`
    ) : (
      'N/A'
    )}
  </Typography>
);

const Verifiers = ({
  businessOwnerKycVerifiedBy,
  companyVerifiedBy,
  isLoading,
}: {
  businessOwnerKycVerifiedBy?: User;
  companyVerifiedBy?: User;
  isLoading: boolean;
}) => {
  return (
    <Grid item xs={12} sm={6} md={4}>
      <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
        Business Verifiers
      </Typography>

      <Paper elevation={2} sx={{ p: 4 }}>
        <Stack gap={1}>
          <VerifierText
            label="Business owner verified by"
            isLoading={isLoading}
            firstName={businessOwnerKycVerifiedBy?.firstName}
            lastName={businessOwnerKycVerifiedBy?.lastName}
          />

          <VerifierText
            label="Company verified by"
            firstName={companyVerifiedBy?.firstName}
            lastName={companyVerifiedBy?.lastName}
          />
        </Stack>
      </Paper>
    </Grid>
  );
};

export default Verifiers;
