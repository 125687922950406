import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@store';
import { SLICE_NAME } from '@variable';

export const selectCompaniesData = (state: RootState) =>
  state[SLICE_NAME].companies;

export const selectApps = (state: RootState) => state[SLICE_NAME].apps;

export const selectIsFetchingCompanies = (state: RootState) =>
  state[SLICE_NAME].isFetchingCompanies;

export const selectIsFetchingCompany = (state: RootState) =>
  state[SLICE_NAME].isFetchingCompany;

export const selectIsFetchingCompanyById = createSelector(
  [selectIsFetchingCompany, (_, props) => props],
  (isFetchingCompany, { id }) => isFetchingCompany[id],
);

export const selectCompanyById = createSelector(
  [selectCompaniesData, (_, props) => props],
  (companies, { id }) => {
    return companies.data?.find(({ id: companyId }) => companyId === id);
  },
);

export const selectCompanyAppsByCompanyId = createSelector(
  [selectApps, (_, props) => props],
  (apps, { id }) => apps[id],
);

export const selectCompanyAppsByCompanyIdAndAppId = createSelector(
  [selectCompanyAppsByCompanyId, (_, props) => props],
  (apps, { appId }) => apps?.find((app) => app.id === appId),
);
