import React from 'react';
import { Typography } from '@mui/material';

interface SectionHeaderProps {
  title: string;
  subtitle: string;
}

const SectionHeader: React.FC<SectionHeaderProps> = ({ title, subtitle }) => {
  return (
    <div style={{ marginBottom: '20px' }}>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      <Typography variant="subtitle2" color="textSecondary">
        {subtitle}
      </Typography>
    </div>
  );
};

export default SectionHeader;
