import { Form, useFormikContext } from 'formik';
import {
  Box,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { Cancel, CheckCircle } from '@mui/icons-material';
import React from 'react';

import PasswordInputField from '@component/PasswordInputField';
import LoadingButton from '@component/LoadingButton';

const calculatePasswordStrength = (values: any) => {
  let strength = 0;

  if (values.newPassword.length >= 8) strength += 25;
  if (/[A-Z]/.test(values.newPassword)) strength += 25;
  if (/[a-z]/.test(values.newPassword)) strength += 25;
  if (/[0-9]/.test(values.newPassword)) strength += 25;

  return strength;
};

const passwordRequirements = [
  {
    label: 'At least 8 characters',
    test: (password: string) => password.length >= 8,
  },
  {
    label: 'At least one uppercase letter',
    test: (password: string) => /[A-Z]/.test(password),
  },
  {
    label: 'At least one lowercase letter',
    test: (password: string) => /[a-z]/.test(password),
  },
  {
    label: 'At least one number',
    test: (password: string) => /[0-9]/.test(password),
  },
  {
    label: 'At least one special character',
    test: (password: string) => /[!@#$%^&*(),.?":{}|<>]/.test(password),
  },
];

const ChangePasswordForm = () => {
  const { values, isValid, dirty, isSubmitting } = useFormikContext<any>();

  return (
    <Form>
      <Box>
        <Box width={'100%'} maxWidth={400} mb={2}>
          <PasswordInputField label="Old Password" name="oldPassword" />
        </Box>

        <Box
          sx={{
            display: 'flex',
            width: '100%',
            gap: 4,
            flexWrap: 'wrap',
          }}
        >
          <Box width={'100%'} maxWidth={400} mb={2}>
            <PasswordInputField label="New Password" name="newPassword" />
            <LinearProgress
              variant="determinate"
              value={calculatePasswordStrength(values)}
              sx={{ mt: 1 }}
            />
            <List sx={{ mt: 2 }}>
              {passwordRequirements.map((requirement, index) => (
                <ListItem key={index}>
                  <ListItemIcon>
                    {requirement.test(values.newPassword) ? (
                      <CheckCircle color="success" />
                    ) : (
                      <Cancel color="error" />
                    )}
                  </ListItemIcon>
                  <ListItemText primary={requirement.label} />
                </ListItem>
              ))}
            </List>
          </Box>

          <Box width={'100%'} maxWidth={400} mb={2}>
            <PasswordInputField
              label="Repeat New Password"
              name="repeatPassword"
            />
          </Box>
        </Box>
      </Box>

      <LoadingButton
        variant="contained"
        color="primary"
        type="submit"
        size="large"
        disabled={!isValid || !dirty}
        isLoading={isSubmitting}
      >
        Change Password
      </LoadingButton>
    </Form>
  );
};

export default ChangePasswordForm;
